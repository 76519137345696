import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import SEO from "../../components/Layout/Seo"
import tw from "twin.macro"

const UyIndex = () => {
    return <div css={tw`h-screen w-screen flex   top-0 left-0 items-center justify-center  mx-auto`}>
        <div
            css={tw`lg:w-5/12 `}
        >
            <StaticImage
                src="../../images/sitio-en-construccion.png"
                height={1050}
                placeholder="none"
                style={{
                }}
                formats={["auto", "webp", "avif"]}
                alt="Sitio web en construcción"
            />
        </div>

    </div>
}


export default UyIndex

export const Head = () =>
    <SEO  description="¡Vende más online en Uruguay con una solución de integración de comercio electrónico! Centraliza la gestión, vende en múltiples canales y aumenta tus ingresos."
    lang="es-UY" title="Autogestiones Uruguay"  />